:root {
  --color-bluescape-v05: #f2f8ff;
  --color-bluescape-dark: #003577;
  --color-bluescape-v100: #0072ff;
  --color-bluescape-v10: #e6f1ff;
  --color-bluescape-v20: #cce2ff;
  --color-bluescape-v40: #99c6ff;
  --color-bluescape-v60: #66aaff;
  --color-bluescape-v80: #338eff;
  --color-charcoal-v02: #f8f9fa;
  --color-charcoal-v05: #f4f4f5;
  --color-charcoal-v100: #2e363d;
  --color-charcoal-v10: #e9eaea;
  --color-charcoal-v20: #d3d4d5;
  --color-charcoal-v30: #bdbfc1;
  --color-charcoal-v40: #a7aaac;
  --color-charcoal-v50: #919597;
  --color-charcoal-v60: #81868a;
  --color-charcoal-v80: #575e63;
  --color-cheese-bleach: #fffef5;
  --color-cheese-dark: #ffffff;
  --color-cheese-default: #fee86c;
  --color-cheese-pale: #fefbe8;
  --color-cheese-soft: #fef09f;
  --color-cheese-softer: #fef7d2;
  --color-cheese-strong: #fedf33;
  --color-grape-bleach: #fbf5ff;
  --color-grape-dark: #913dcc;
  --color-grape-default: #c87aff;
  --color-grape-pale: #f6ebff;
  --color-grape-soft: #ddadff;
  --color-grape-softer: #eed7ff;
  --color-grape-strong: #b54dff;
  --color-peony-bleach: #fff4fc;
  --color-peony-dark: #cc29a2;
  --color-peony-default: #ff6bd9;
  --color-peony-pale: #ffe8f9;
  --color-peony-soft: #ff9ee6;
  --color-peony-softer: #ffd2f3;
  --color-peony-strong: #ff4dd1;
  --color-sherbet-bleach: #fff4f4;
  --color-sherbet-dark: #cc3d3d;
  --color-sherbet-default: #ff6e6e;
  --color-sherbet-pale: #ffe9e9;
  --color-sherbet-soft: #ffa1a1;
  --color-sherbet-softer: #ffd3d3;
  --color-sherbet-strong: #ff4d4d;
  --color-tangerine-bleach: #fff8f4;
  --color-tangerine-dark: #e07431;
  --color-tangerine-default: #ffa46b;
  --color-tangerine-pale: #fff1e8;
  --color-tangerine-soft: #ffc39e;
  --color-tangerine-softer: #ffe3d2;
  --color-tangerine-strong: #ff914d;
  --color-turtle-bleach: #f2fcf9;
  --color-turtle-dark: #008562;
  --color-turtle-default: #59d4b4;
  --color-turtle-pale: #e6f8f3;
  --color-turtle-soft: #82dec6;
  --color-turtle-softer: #cdf2e8;
  --color-turtle-strong: #00d49c;
}

/* Button, "Light bg" */
.theme-white,
.theme-charcoal-bleach,
.theme-bluescape-bleach,
.theme-bluescape-pale {
  --theme-simple-quote-color: var(--color-bluescape-v40);

  --theme-primary-button-background-active: var(--color-bluescape-v60);
  --theme-primary-button-background-disabled: var(--color-bluescape-v20);
  --theme-primary-button-background-hover: var(--color-bluescape-v80);
  --theme-primary-button-background: var(--color-bluescape-v100);

  --theme-primary-button-border-active: var(--color-bluescape-v60);
  --theme-primary-button-border-disabled: var(--color-bluescape-v20);
  --theme-primary-button-border-hover: var(--color-bluescape-v80);
  --theme-primary-button-border: var(--color-bluescape-v100);

  --theme-primary-button-color-active: white;
  --theme-primary-button-color-disabled: var(--color-bluescape-v05);
  --theme-primary-button-color-hover: white;
  --theme-primary-button-color: white;

  --theme-secondary-button-background-active: var(--color-bluescape-v100);
  --theme-secondary-button-background-disabled: none;
  --theme-secondary-button-background-hover: var(--color-bluescape-v10);
  --theme-secondary-button-background: none;

  --theme-secondary-button-border-active: var(--color-bluescape-v100);
  --theme-secondary-button-border-disabled: var(--color-bluescape-v20);
  --theme-secondary-button-border-hover: var(--color-bluescape-v80);
  --theme-secondary-button-border: var(--color-bluescape-v100);

  --theme-secondary-button-color-active: white;
  --theme-secondary-button-color-disabled: var(--color-bluescape-v20);
  --theme-secondary-button-color-hover: var(--color-bluescape-v80);
  --theme-secondary-button-color: var(--color-bluescape-v100);
}

/* Button, "Strong bg" */
.theme-bluescape-strong {
  --theme-primary-button-background-active: var(--color-bluescape-v05);
  --theme-primary-button-background-disabled: var(--color-bluescape-v40);
  --theme-primary-button-background-hover: var(--color-bluescape-v10);
  --theme-primary-button-background: var(--color-bluescape-v20);

  --theme-primary-button-border-active: var(--color-bluescape-v05);
  --theme-primary-button-border-disabled: var(--color-bluescape-v40);
  --theme-primary-button-border-hover: var(--color-bluescape-v10);
  --theme-primary-button-border: var(--color-bluescape-v20);

  --theme-primary-button-color-active: var(--color-bluescape-v100);
  --theme-primary-button-color-disabled: var(--color-bluescape-v20);
  --theme-primary-button-color-hover: var(--color-bluescape-v100);
  --theme-primary-button-color: var(--color-bluescape-v100);

  --theme-secondary-button-background-active: none;
  --theme-secondary-button-background-disabled: none;
  --theme-secondary-button-background-hover: none;
  --theme-secondary-button-background: none;

  --theme-secondary-button-border-active: var(--color-bluescape-v20);
  --theme-secondary-button-border-disabled: var(--color-bluescape-v40);
  --theme-secondary-button-border-hover: white;
  --theme-secondary-button-border: var(--color-bluescape-v20);

  --theme-secondary-button-color-active: var(--color-bluescape-v20);
  --theme-secondary-button-color-disabled: var(--color-bluescape-v40);
  --theme-secondary-button-color-hover: white;
  --theme-secondary-button-color: var(--color-bluescape-v20);
}

/* Button, "Dark bg" */
.theme-charcoal-dark,
.theme-bluescape-dark {
  --theme-primary-button-background-active: var(--color-bluescape-v60);
  --theme-primary-button-background-disabled: var(--color-bluescape-v40);
  --theme-primary-button-background-hover: var(--color-bluescape-v80);
  --theme-primary-button-background: var(--color-bluescape-v100);

  --theme-primary-button-border-active: var(--color-bluescape-v60);
  --theme-primary-button-border-disabled: var(--color-bluescape-v40);
  --theme-primary-button-border-hover: var(--color-bluescape-v80);
  --theme-primary-button-border: var(--color-bluescape-v100);

  --theme-primary-button-color-active: white;
  --theme-primary-button-color-disabled: var(--color-bluescape-v20);
  --theme-primary-button-color-hover: white;
  --theme-primary-button-color: white;

  --theme-secondary-button-background-active: none;
  --theme-secondary-button-background-disabled: none;
  --theme-secondary-button-background-hover: none;
  --theme-secondary-button-background: none;

  --theme-secondary-button-border-active: var(--color-bluescape-v20);
  --theme-secondary-button-border-disabled: var(--color-bluescape-v40);
  --theme-secondary-button-border-hover: var(--color-bluescape-v40);
  --theme-secondary-button-border: var(--color-bluescape-v60);

  --theme-secondary-button-color-active: var(--color-bluescape-v20);
  --theme-secondary-button-color-disabled: var(--color-bluescape-v40);
  --theme-secondary-button-color-hover: var(--color-bluescape-v40);
  --theme-secondary-button-color: var(--color-bluescape-v60);
}

.theme-white, .theme-charcoal-bleach {
  --theme-slider-button-normal: var(--color-charcoal-v10);
  --theme-slider-button-hover: var(--color-charcoal-v20);
  --theme-slider-button-active: var(--color-charcoal-v40);
  --theme-slider-arrow-normal: var(--color-charcoal-v60);
  --theme-slider-arrow-active: var(--color-charcoal-v80);
}

.theme-white {
  --theme-border-color: var(--color-charcoal-v10);
  --theme-border-color-hover: var(--color-charcoal-v20);
  --theme-background: white;
  --theme-subheading-color: var(--color-bluescape-v100);
  --theme-heading-color: var(--color-charcoal-v100);
  --theme-emphasized-color: var(--color-bluescape-v100);
  --theme-text-color: var(--color-charcoal-v100);
  --theme-detail-color: var(--color-bluescape-v60);
  --theme-link-color: var(--color-bluescape-v100);
}

.theme-charcoal-bleach {
  --theme-border-color: var(--color-charcoal-v10);
  --theme-border-color-hover: var(--color-charcoal-v20);
  --theme-background: var(--color-charcoal-v02);
  --theme-subheading-color: var(--color-bluescape-v100);
  --theme-heading-color: var(--color-charcoal-v100);
  --theme-emphasized-color: var(--color-bluescape-v100);
  --theme-text-color: var(--color-charcoal-v100);
  --theme-detail-color: var(--color-bluescape-v60);
  --theme-link-color: var(--color-bluescape-v100);
}

.theme-bluescape-bleach, .theme-bluescape-pale {
  --theme-slider-button-normal: var(--color-bluescape-v20);
  --theme-slider-button-hover: var(--color-bluescape-v40);
  --theme-slider-button-active: var(--color-bluescape-v60);
  --theme-slider-arrow-normal: var(--color-bluescape-v100);
  --theme-slider-arrow-active: var(--color-bluescape-v100);
}

.theme-bluescape-bleach {
  --theme-border-color: var(--color-bluescape-v20);
  --theme-border-color-hover: var(--color-bluescape-v40);
  --theme-background: var(--color-bluescape-v05);
  --theme-subheading-color: var(--color-bluescape-v100);
  --theme-heading-color: var(--color-charcoal-v100);
  --theme-emphasized-color: var(--color-bluescape-v100);
  --theme-text-color: var(--color-charcoal-v100);
  --theme-detail-color: var(--color-bluescape-v60);
  --theme-link-color: var(--color-bluescape-v100);
}

.theme-bluescape-pale {
  --theme-border-color: var(--color-bluescape-v20);
  --theme-border-color-hover: var(--color-bluescape-v40);
  --theme-background: var(--color-bluescape-v10);
  --theme-subheading-color: var(--color-bluescape-v100);
  --theme-heading-color: var(--color-charcoal-v100);
  --theme-emphasized-color: var(--color-bluescape-v100);
  --theme-text-color: var(--color-charcoal-v100);
  --theme-detail-color: var(--color-bluescape-v60);
  --theme-link-color: var(--color-bluescape-v100);
}

.theme-charcoal-dark {
  --theme-border-color: var(--color-charcoal-v80);
  --theme-border-color-hover: var(--color-charcoal-v60);
  --theme-background: var(--color-charcoal-v100);
  --theme-subheading-color: white;
  --theme-heading-color: white;
  --theme-emphasized-color: var(--color-bluescape-v60);
  --theme-text-color: white;
  --theme-detail-color: var(--color-bluescape-v60);
  --theme-link-color: var(--color-bluescape-v60);
}

.theme-bluescape-strong {
  --theme-border-color: var(--color-bluescape-v40);
  --theme-border-color-hover: var(--color-bluescape-v40);
  --theme-background: var(--color-bluescape-v100);
  --theme-subheading-color: white;
  --theme-heading-color: white;
  --theme-emphasized-color: var(--color-bluescape-v20);
  --theme-text-color: white;
  --theme-detail-color: var(--color-bluescape-v20);
  --theme-link-color: var(--color-bluescape-v20);
}

.theme-bluescape-dark {
  --theme-border-color: var(--color-bluescape-v40);
  --theme-border-color-hover: var(--color-bluescape-v20);
  --theme-background: var(--color-bluescape-dark);
  --theme-subheading-color: white;
  --theme-heading-color: white;
  --theme-emphasized-color: var(--color-bluescape-v60);
  --theme-text-color: white;
  --theme-detail-color: var(--color-bluescape-v20);
  --theme-link-color: var(--color-bluescape-v60);
}

.theme-grape-bleach {
  --theme-background: var(--color-grape-bleach);
}

.theme-grape-pale {
  --theme-background: var(--color-grape-pale);
}

.theme-grape-bleach,
.theme-grape-pale {
  --theme-simple-quote-color: var(--color-grape-soft);
  --theme-border-color: var(--color-grape-softer);
  --theme-border-color-hover: var(--color-grape-soft);
  --theme-detail-color: var(--color-grape-default);
  --theme-heading-color: var(--color-charcoal-v100);
  --theme-emphasized-color: var(--color-grape-strong);
  --theme-link-color: var(--color-bluescape-v100);
  --theme-slider-button-normal: var(--color-grape-softer);
  --theme-slider-button-hover: var(--color-grape-soft);
  --theme-slider-button-active: var(--color-grape-default);
  --theme-slider-arrow-normal: var(--color-grape-strong);
  --theme-slider-arrow-active: var(--color-grape-dark);
  --theme-primary-button-background-active: var(--color-grape-soft);
  --theme-primary-button-background-disabled: var(--color-grape-softer);
  --theme-primary-button-background-hover: var(--color-grape-default);
  --theme-primary-button-background: var(--color-grape-strong);
  --theme-primary-button-border-active: var(--color-grape-soft);
  --theme-primary-button-border-disabled: var(--color-grape-softer);
  --theme-primary-button-border-hover: var(--color-grape-default);
  --theme-primary-button-border: var(--color-grape-strong);
  --theme-primary-button-color-active: white;
  --theme-primary-button-color-disabled: white;
  --theme-primary-button-color-hover: white;
  --theme-primary-button-color: white;
  --theme-secondary-button-background-active: var(--color-grape-strong);
  --theme-secondary-button-background-disabled: none;
  --theme-secondary-button-background-hover: var(--color-grape-pale);
  --theme-secondary-button-background: none;
  --theme-secondary-button-border-active: var(--color-grape-strong);
  --theme-secondary-button-border-disabled: var(--color-grape-softer);
  --theme-secondary-button-border-hover: var(--color-grape-default);
  --theme-secondary-button-border: var(--color-grape-strong);
  --theme-secondary-button-color-active: white;
  --theme-secondary-button-color-disabled: var(--color-grape-softer);
  --theme-secondary-button-color-hover: var(--color-grape-default);
  --theme-secondary-button-color: var(--color-grape-strong);
  --theme-subheading-color: var(--color-grape-strong);
  --theme-text-color: var(--color-charcoal-v100);
}

.theme-tangerine-bleach {
  --theme-background: var(--color-tangerine-bleach);
}

.theme-tangerine-pale {
  --theme-background: var(--color-tangerine-pale);
}

.theme-tangerine-bleach,
.theme-tangerine-pale {
  --theme-simple-quote-color: var(--color-tangerine-soft);
  --theme-border-color: var(--color-tangerine-softer);
  --theme-border-color-hover: var(--color-tangerine-soft);
  --theme-detail-color: var(--color-tangerine-default);
  --theme-heading-color: var(--color-charcoal-v100);
  --theme-emphasized-color: var(--color-tangerine-strong);
  --theme-link-color: var(--color-bluescape-v100);
  --theme-slider-button: var(--color-tangerine-softer);
  --theme-slider-button-hover: var(--color-tangerine-soft);
  --theme-slider-button-active: var(--color-tangerine-default);
  --theme-slider-arrow-normal: var(--color-tangerine-strong);
  --theme-slider-arrow-active: var(--color-tangerine-dark);
  --theme-primary-button-background-active: var(--color-tangerine-soft);
  --theme-primary-button-background-disabled: var(--color-tangerine-softer);
  --theme-primary-button-background-hover: var(--color-tangerine-default);
  --theme-primary-button-background: var(--color-tangerine-strong);
  --theme-primary-button-border-active: var(--color-tangerine-soft);
  --theme-primary-button-border-disabled: var(--color-tangerine-softer);
  --theme-primary-button-border-hover: var(--color-tangerine-default);
  --theme-primary-button-border: var(--color-tangerine-strong);
  --theme-primary-button-color-active: white;
  --theme-primary-button-color-disabled: white;
  --theme-primary-button-color-hover: white;
  --theme-primary-button-color: white;
  --theme-secondary-button-background-active: var(--color-tangerine-strong);
  --theme-secondary-button-background-disabled: none;
  --theme-secondary-button-background-hover: var(--color-tangerine-pale);
  --theme-secondary-button-background: none;
  --theme-secondary-button-border-active: var(--color-tangerine-strong);
  --theme-secondary-button-border-disabled: var(--color-tangerine-softer);
  --theme-secondary-button-border-hover: var(--color-tangerine-default);
  --theme-secondary-button-border: var(--color-tangerine-strong);
  --theme-secondary-button-color-active: white;
  --theme-secondary-button-color-disabled: var(--color-tangerine-softer);
  --theme-secondary-button-color-hover: var(--color-tangerine-default);
  --theme-secondary-button-color: var(--color-tangerine-strong);
  --theme-subheading-color: var(--color-tangerine-strong);
  --theme-text-color: var(--color-charcoal-v100);
}

.theme-peony-bleach {
  --theme-background: var(--color-peony-bleach);
}

.theme-peony-pale {
  --theme-background: var(--color-peony-pale);
}

.theme-peony-bleach,
.theme-peony-pale {
  --theme-simple-quote-color: var(--color-peony-soft);
  --theme-border-color: var(--color-peony-softer);
  --theme-border-color-hover: var(--color-peony-soft);
  --theme-detail-color: var(--color-peony-default);
  --theme-heading-color: var(--color-charcoal-v100);
  --theme-emphasized-color: var(--color-peony-strong);
  --theme-link-color: var(--color-bluescape-v100);
  --theme-slider-button-normal: var(--color-peony-softer);
  --theme-slider-button-hover: var(--color-peony-soft);
  --theme-slider-button-active: var(--color-peony-default);
  --theme-slider-arrow-normal: var(--color-peony-strong);
  --theme-slider-arrow-active: var(--color-peony-dark);
  --theme-primary-button-background-active: var(--color-peony-soft);
  --theme-primary-button-background-disabled: var(--color-peony-softer);
  --theme-primary-button-background-hover: var(--color-peony-default);
  --theme-primary-button-background: var(--color-peony-strong);
  --theme-primary-button-border-active: var(--color-peony-soft);
  --theme-primary-button-border-disabled: var(--color-peony-softer);
  --theme-primary-button-border-hover: var(--color-peony-default);
  --theme-primary-button-border: var(--color-peony-strong);
  --theme-primary-button-color-active: white;
  --theme-primary-button-color-disabled: white;
  --theme-primary-button-color-hover: white;
  --theme-primary-button-color: white;
  --theme-secondary-button-background-active: var(--color-peony-strong);
  --theme-secondary-button-background-disabled: none;
  --theme-secondary-button-background-hover: var(--color-peony-pale);
  --theme-secondary-button-background: none;
  --theme-secondary-button-border-active: var(--color-peony-strong);
  --theme-secondary-button-border-disabled: var(--color-peony-softer);
  --theme-secondary-button-border-hover: var(--color-peony-default);
  --theme-secondary-button-border: var(--color-peony-strong);
  --theme-secondary-button-color-active: white;
  --theme-secondary-button-color-disabled: var(--color-peony-softer);
  --theme-secondary-button-color-hover: var(--color-peony-default);
  --theme-secondary-button-color: var(--color-peony-strong);
  --theme-subheading-color: var(--color-peony-strong);
  --theme-text-color: var(--color-charcoal-v100);
}

.theme-sherbet-bleach {
  --theme-background: var(--color-sherbet-bleach);
}

.theme-sherbet-pale {
  --theme-background: var(--color-sherbet-pale);
}

.theme-sherbet-bleach,
.theme-sherbet-pale {
  --theme-simple-quote-color: var(--color-sherbet-soft);
  --theme-border-color: var(--color-sherbet-softer);
  --theme-border-color-hover: var(--color-sherbet-soft);
  --theme-detail-color: var(--color-sherbet-default);
  --theme-heading-color: var(--color-charcoal-v100);
  --theme-emphasized-color: var(--color-sherbet-strong);
  --theme-link-color: var(--color-bluescape-v100);
  --theme-slider-button-normal: var(--color-sherbet-softer);
  --theme-slider-button-hover: var(--color-sherbet-soft);
  --theme-slider-button-active: var(--color-sherbet-default);
  --theme-slider-arrow-normal: var(--color-sherbet-strong);
  --theme-slider-arrow-active: var(--color-sherbet-dark);
  --theme-primary-button-background-active: var(--color-sherbet-soft);
  --theme-primary-button-background-disabled: var(--color-sherbet-softer);
  --theme-primary-button-background-hover: var(--color-sherbet-default);
  --theme-primary-button-background: var(--color-sherbet-strong);
  --theme-primary-button-border-active: var(--color-sherbet-soft);
  --theme-primary-button-border-disabled: var(--color-sherbet-softer);
  --theme-primary-button-border-hover: var(--color-sherbet-default);
  --theme-primary-button-border: var(--color-sherbet-strong);
  --theme-primary-button-color-active: white;
  --theme-primary-button-color-disabled: white;
  --theme-primary-button-color-hover: white;
  --theme-primary-button-color: white;
  --theme-secondary-button-background-active: var(--color-sherbet-strong);
  --theme-secondary-button-background-disabled: none;
  --theme-secondary-button-background-hover: var(--color-sherbet-pale);
  --theme-secondary-button-background: none;
  --theme-secondary-button-border-active: var(--color-sherbet-strong);
  --theme-secondary-button-border-disabled: var(--color-sherbet-softer);
  --theme-secondary-button-border-hover: var(--color-sherbet-default);
  --theme-secondary-button-border: var(--color-sherbet-strong);
  --theme-secondary-button-color-active: white;
  --theme-secondary-button-color-disabled: var(--color-sherbet-softer);
  --theme-secondary-button-color-hover: var(--color-sherbet-default);
  --theme-secondary-button-color: var(--color-sherbet-strong);
  --theme-subheading-color: var(--color-sherbet-strong);
  --theme-text-color: var(--color-charcoal-v100);
}

.theme-tangerine-bleach {
  --theme-background: var(--color-tangerine-bleach);
}

.theme-tangerine-pale {
  --theme-background: var(--color-tangerine-pale);
}

.theme-tangerine-bleach,
.theme-tangerine-pale {
  --theme-simple-quote-color: var(--color-tangerine-soft);
  --theme-border-color: var(--color-tangerine-softer);
  --theme-border-color-hover: var(--color-tangerine-soft);
  --theme-detail-color: var(--color-tangerine-default);
  --theme-heading-color: var(--color-charcoal-v100);
  --theme-emphasized-color: var(--color-tangerine-strong);
  --theme-link-color: var(--color-bluescape-v100);
  --theme-slider-button-normal: var(--color-tangerine-softer);
  --theme-slider-button-hover: var(--color-tangerine-soft);
  --theme-slider-button-active: var(--color-tangerine-default);
  --theme-slider-arrow-normal: var(--color-tangerine-strong);
  --theme-slider-arrow-active: var(--color-tangerine-dark);
  --theme-primary-button-background-active: var(--color-tangerine-soft);
  --theme-primary-button-background-disabled: var(--color-tangerine-softer);
  --theme-primary-button-background-hover: var(--color-tangerine-default);
  --theme-primary-button-background: var(--color-tangerine-strong);
  --theme-primary-button-border-active: var(--color-tangerine-soft);
  --theme-primary-button-border-disabled: var(--color-tangerine-softer);
  --theme-primary-button-border-hover: var(--color-tangerine-default);
  --theme-primary-button-border: var(--color-tangerine-strong);
  --theme-primary-button-color-active: white;
  --theme-primary-button-color-disabled: white;
  --theme-primary-button-color-hover: white;
  --theme-primary-button-color: white;
  --theme-secondary-button-background-active: var(--color-tangerine-strong);
  --theme-secondary-button-background-disabled: none;
  --theme-secondary-button-background-hover: var(--color-tangerine-pale);
  --theme-secondary-button-background: none;
  --theme-secondary-button-border-active: var(--color-tangerine-strong);
  --theme-secondary-button-border-disabled: var(--color-tangerine-softer);
  --theme-secondary-button-border-hover: var(--color-tangerine-default);
  --theme-secondary-button-border: var(--color-tangerine-strong);
  --theme-secondary-button-color-active: white;
  --theme-secondary-button-color-disabled: var(--color-tangerine-softer);
  --theme-secondary-button-color-hover: var(--color-tangerine-default);
  --theme-secondary-button-color: var(--color-tangerine-strong);
  --theme-subheading-color: var(--color-tangerine-strong);
  --theme-text-color: var(--color-charcoal-v100);
}

.theme-turtle-bleach {
  --theme-background: var(--color-turtle-bleach);
}

.theme-turtle-pale {
  --theme-background: var(--color-turtle-pale);
}

.theme-turtle-bleach,
.theme-turtle-pale {
  --theme-simple-quote-color: var(--color-turtle-soft);
  --theme-border-color: var(--color-turtle-softer);
  --theme-border-color-hover: var(--color-turtle-soft);
  --theme-detail-color: var(--color-turtle-default);
  --theme-heading-color: var(--color-charcoal-v100);
  --theme-emphasized-color: var(--color-turtle-strong);
  --theme-link-color: var(--color-bluescape-v100);
  --theme-slider-button-normal: var(--color-turtle-softer);
  --theme-slider-button-hover: var(--color-turtle-soft);
  --theme-slider-button-active: var(--color-turtle-default);
  --theme-slider-arrow-normal: var(--color-turtle-strong);
  --theme-slider-arrow-active: var(--color-turtle-dark);
  --theme-primary-button-background-active: var(--color-turtle-soft);
  --theme-primary-button-background-disabled: var(--color-turtle-softer);
  --theme-primary-button-background-hover: var(--color-turtle-default);
  --theme-primary-button-background: var(--color-turtle-strong);
  --theme-primary-button-border-active: var(--color-turtle-soft);
  --theme-primary-button-border-disabled: var(--color-turtle-softer);
  --theme-primary-button-border-hover: var(--color-turtle-default);
  --theme-primary-button-border: var(--color-turtle-strong);
  --theme-primary-button-color-active: white;
  --theme-primary-button-color-disabled: white;
  --theme-primary-button-color-hover: white;
  --theme-primary-button-color: white;
  --theme-secondary-button-background-active: var(--color-turtle-strong);
  --theme-secondary-button-background-disabled: none;
  --theme-secondary-button-background-hover: var(--color-turtle-pale);
  --theme-secondary-button-background: none;
  --theme-secondary-button-border-active: var(--color-turtle-strong);
  --theme-secondary-button-border-disabled: var(--color-turtle-softer);
  --theme-secondary-button-border-hover: var(--color-turtle-default);
  --theme-secondary-button-border: var(--color-turtle-strong);
  --theme-secondary-button-color-active: white;
  --theme-secondary-button-color-disabled: var(--color-turtle-softer);
  --theme-secondary-button-color-hover: var(--color-turtle-default);
  --theme-secondary-button-color: var(--color-turtle-strong);
  --theme-subheading-color: var(--color-turtle-strong);
  --theme-text-color: var(--color-charcoal-v100);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.3;
}
a, .link {
  cursor: pointer;
  color: var(--color-bluescape-v100);
  text-decoration: none;
}
a:hover, .link:hover {
  color: var(--color-bluescape-v60);
}
h1 {
  font-size: 40px;
  font-weight: 400;
}
h2 {
  font-size: 32px;
  font-weight: 400;
}
h3 {
  font-size: 24px;
  font-weight: 600;
}
h4 {
  font-size: 21px;
  font-weight: 600;
}
h5 {
  font-size: 18px;
  font-weight: 600;
}
h6 {
  font-size: 15px;
  font-weight: 600;
}
hr {
  border: 0;
  margin: 30px 0;
  height: 1px;
  background: #e9eaea;
}

#hs-eu-cookie-confirmation {
  display: none;
}